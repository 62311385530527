import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import ReactTimeAgo from 'react-time-ago';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  // Popover,
  Checkbox,
  TableRow,
  // MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  Tooltip,
  TableContainer,
  TablePagination,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  // DialogContent,
  // DialogContentText,
  DialogActions,
  TextField,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  useMutation,
  useQuery,
  useQueryClient,
  // useMutation,
} from 'react-query'
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { SnackbarContext } from '../components/snackbar/SnackbarContext';
// sections
import { DeviceListHead, DeviceListToolbar } from '../sections/@dashboard/device';
import { getDeviceModelName } from '../helper';
// hooks
import { useLocalStorage } from '../hooks/useLocalStorage';
import getDevices from '../hooks/queries/getDevices';
import patchDevice from '../hooks/mutations/patchDevice';
import deleteDevice from '../hooks/mutations/deleteDevice';
import updateDevicesMode from '../hooks/mutations/updateDevicesMode';
import updateDevicesStatus from '../hooks/mutations/updateDevicesStatus';
// utils
import { fDateTime } from '../utils/formatTime';

// ----------------------------------------------------------------------


const TABLE_HEAD = [
  { id: 'device', label: 'Device' },
  { id: 'name', label: 'Name' },
  { id: 'info', label: 'Info' },
  { id: 'mode', label: 'Mode' },
  { id: 'status', label: 'Status' },
  { id: 'stopReason', label: 'Reason' },
  { id: 'lastPerformTaskAt', label: 'Last Run', width: '140px' },
  { id: 'sendMessageStatistic', label: 'Send', width: '200px' },
  // { id: 'sendMessageCount', label: 'Send', width: '60px' },
  // { id: 'sendMessageSuccessCount', label: 'Success', width: '60px' },
  // { id: 'sendMessageFailedCount', label: 'Failed', width: '60px' },
  { id: '' },
];

const ONLINE_TIME_DURATION = 60000;

// ----------------------------------------------------------------------

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

function stringComparator(orderBy, order) {
  return (a, b) => order === 'desc' ? -collator.compare(a[orderBy], b[orderBy]) : collator.compare(a[orderBy], b[orderBy]);
}

function dateComparator(orderBy, order) {
  return (a, b) => {
    const direction = order === 'desc' ? -1 : 1;
    const timeA = new Date(a[orderBy] ?? 0).valueOf();
    const timeB = new Date(b[orderBy] ?? 0).valueOf();
    if (timeA === timeB) return 0;
    if (timeA < timeB) return direction * 1;
    return direction * -1;
  }
}

function getComparator(order, orderBy) {
  if (orderBy === 'lastPerformTaskAt') {
    return dateComparator(orderBy, order);
  }
  return stringComparator(orderBy, order);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_device) => _device.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


EditableName.propTypes = {
  deviceId: PropTypes.string,
  value: PropTypes.string,
};

function EditableName({ deviceId, value: inputValue }) {
  const [value, setValue] = useState(inputValue ?? '');
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const queryClient = useQueryClient();
  const { createNotification } = useContext(SnackbarContext);

  const patchDeviceMutation = useMutation(patchDevice, {
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['devices']);
      createNotification('success', 'Rename device success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Rename device failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
      setIsEditing(false);
      setIsSaving(false);
    },
  });

  const handleSave = () => {
    patchDeviceMutation.mutate({ deviceId, deviceInfo: { name: value } });
  };

  const handleCancel = () => {
    setValue(inputValue);
    setIsEditing(false);
  };

  const handleMouseOver = () => { setIsHovering(true); }
  const handleMouseOut = () => { setIsHovering(false); }

  const handleKeyDown = (event) => {
    if (event.code === 'Enter') {
      handleSave();
    } else if (event.code === 'Escape') {
      handleCancel();
    }
  };

  return !isEditing ? (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}>
      <Typography
        sx={{
          height: '24px',
          minWidth: '100%',
          width: '100%',
        }}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {value}
      </Typography>
      {isHovering
        && <IconButton size="small" color="inherit" onClick={() => setIsEditing(true)}>
          <Iconify icon={'eva:edit-fill'} />
        </IconButton>}
    </Box>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        variant="standard"
        autoFocus
        sx={{ maxWidth: '100px' }}
        inputProps={{
          sx: {
            maxWidth: '100px',
            width: '100%',
            padding: 1,
          }
        }}
        value={value}
        onChange={(event) => setValue(event.target.value ?? '')}
        onKeyDown={handleKeyDown}
        disabled={isSaving}
      />
      <IconButton size="small" color="inherit" onClick={handleSave} disabled={isSaving}>
        <Iconify icon={'eva:checkmark-fill'} />
      </IconButton>
      <IconButton size="small" color="inherit" onClick={handleCancel} disabled={isSaving}>
        <Iconify icon={'eva:close-fill'} />
      </IconButton>
    </Box>
  );
}

const getDeviceStatusLabel = (mode, status, subStatus) => {
  switch (status) {
    case 'stop':
      return <Label color="error">Stopped</Label>;
    case 'running':
      if (mode === 'check-imessage') {
        return <Label color="info">Check iMessage</Label>;
      }
      if (subStatus === 'global-paused') {
        return <Label color="warning">Send iMessage (User Paused)</Label>;
      }
      if (subStatus === 'rate-limited') {
        return <Label color="warning">Send iMessage (Rate Limited)</Label>;
      }
      if (subStatus === 'allow-hours-paused') {
        return <Label color="warning">Send iMessage (Not in sending time)</Label>;
      }
      return <Label color="success">Send iMessage</Label>;
    default:
      return <Label>{status}</Label>
  }
};

const getDeviceStatisticLabel = (sendMessageCount, sendMessageSuccessCount, sendMessageFailedCount) => (
  <>
    <Tooltip title="Number of messages sent">
      <Chip label={sendMessageCount} color="primary" size="small" />
    </Tooltip>
    {' '}
    <Tooltip title="Number of messages send success">
      <Chip label={sendMessageSuccessCount} color="success" size="small" />
    </Tooltip>
    {' '}
    <Tooltip title="Number of messages send failed">
      <Chip label={sendMessageFailedCount} color="error" size="small" />
    </Tooltip>
  </>
);

// const getDeviceStatisticLabel = (count, time, color) => (
//   <>
//     <Tooltip title={time ? `${fDateTime(time)}` : ''}>
//       <Chip label={count ?? 0} color={color} size="small" />
//     </Tooltip>
//   </>
// );

const getDeviceStopReasonLabel = (stopReason) => {
  switch (stopReason) {
    case 'user-account-expired':
      return (
        <Tooltip title={`Your account has expired or not purchased yet. Please contact admin to renew your account`}>
          <Typography variant="caption" color="error">Account expired</Typography>
        </Tooltip>
      );
    case 'user-devices-limit':
      return (
        <Tooltip title={`Your account has limited number of devices. Please contact admin if you want to use more devices`}>
          <Typography variant="caption" color="warn">Over number devices allowed</Typography>
        </Tooltip>
      );
    case 'device-agent-error':
      return (
        <Tooltip title={`Try re-install app and/or using PowerSelector to run "UserSpace Reboot"`}>
          <Typography variant="caption" color="error">AutoMess app crashed</Typography>
        </Tooltip>
      );
    case 'device-imessage-not-enabled':
      return (
        <Tooltip title={`Current device is not enable iMessage, please try to active iMessage manually`}>
          <Typography variant="caption" color="error">iMessage not enabled</Typography>
        </Tooltip>
      );
    case 'device-no-imessage-account':
      return (
        <Tooltip title={`Current device is not logged in with iMessage, please try to active iMessage manually`}>
          <Typography variant="caption" color="error">No iMessage account found</Typography>
        </Tooltip>
      );
    case 'device-imessage-banned':
      return (
        <Tooltip title={`This icloud was banned from iMessage, please try to login to new iMessage account and active it manually`}>
          <Typography variant="caption" color="error">iMessage account was banned</Typography>
        </Tooltip>
      );
    case 'user-stopped':
      return '';
    default:
      return stopReason;
  }
};

export default function DevicePage() {
  const { getItem, setItem } = useLocalStorage();
  // const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [orderBy, _setOrderBy] = useState(() => getItem('device_orderBy') ?? 'name');
  const setOrderBy = (value) => {
    _setOrderBy(value);
    setItem('device_orderBy', value);
  };
  const [order, _setOrder] = useState(() => getItem('device_order') ?? 'asc');
  const setOrder = (value) => {
    _setOrder(value);
    setItem('device_order', value);
  };
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [perPage, _setRowsPerPage] = useState(() => Number(getItem('device_perPage') ?? 100));
  const setRowsPerPage = (value) => {
    _setRowsPerPage(value);
    setItem('device_perPage', value);
  };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const { isLoading: isLoadingDevices, data: devices } = useQuery(['devices'], getDevices, {
    refetchInterval: 10000,
  });
  const dataCount = (devices ?? []).length;

  const queryClient = useQueryClient();
  const { createNotification } = useContext(SnackbarContext);

  const updateDevicesModeMutation = useMutation(updateDevicesMode, {
    onMutate: () => {
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['devices']);
      createNotification('success', 'Update device mode success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Update device mode failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
    },
  });

  const updateDevicesStatusMutation = useMutation(updateDevicesStatus, {
    onMutate: () => {
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['devices']);
      createNotification('success', 'Update device status success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Update device status failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
    },
  });

  const deleteDeviceMutation = useMutation(deleteDevice, {
    onMutate: () => {
      setIsDeleting(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['devices']);
      createNotification('success', 'Delete device success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Delete device failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
      setOpenDeleteDialog(null);
      setIsDeleting(false);
    },
  });

  // const handleOpenMenu = (event) => {
  //   setOpen(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const handleRequestSort = (event, property) => {
    if (property === 'info') {
      event.preventDefault();
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAll = (event, data) => {
    if (event.target.checked) {
      const newSelecteds = (data ?? []).map((n) => n._id);
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems([]);
  };

  const handleSelectItem = (event, deviceId) => {
    const selectedIndex = selectedItems.indexOf(deviceId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, deviceId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
    }
    setSelectedItems(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleUpdateDevicesMode = (deviceIds, mode, clearSelect = false) => {
    updateDevicesModeMutation.mutate({ deviceIds, mode });
    if (clearSelect) setSelectedItems([]);
  };

  const handleUpdateDevicesStatus = (deviceIds, status, clearSelect = false) => {
    updateDevicesStatusMutation.mutate({ deviceIds, status });
    if (clearSelect) setSelectedItems([]);
  };

  const handleDeleteDevice = () => {
    deleteDeviceMutation.mutate({ deviceId: openDeleteDialog });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * perPage - dataCount) : 0;
  const filteredDevices = applySortFilter(devices ?? [], getComparator(order, orderBy), filterName);
  const isNotFound = !filteredDevices.length && !!filterName;

  return (
    <>
      <Helmet>
        <title>SpamImess - Devices</title>
      </Helmet>

      <Container maxWidth={false} sx={{ minWidth: '1600px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Devices
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:question-mark-circle-outline" />}>
            How to install
          </Button>
        </Stack>

        <Card>
          <DeviceListToolbar selectedItems={selectedItems} filterName={filterName} onFilterName={handleFilterByName} onUpdateDevicesMode={handleUpdateDevicesMode} onUpdateDevicesStatus={handleUpdateDevicesStatus} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <DeviceListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataCount}
                  numSelected={selectedItems.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={(event) => handleSelectAll(event, devices)}
                />
                <TableBody>
                  {!isLoadingDevices && !!dataCount && filteredDevices.slice(page * perPage, page * perPage + perPage).map((row) => {
                    const { _id: deviceId, serialNumber, model, name, batteryPercent, isCharging, mode, status, subStatus, stopReason, createdAt, lastSeenAt, sendMessageCount, lastPerformTaskAt, sendMessageSuccessCount, lastSendMessageSuccessAt, sendMessageFailedCount, lastSendMessageFailedAt } = row;
                    const isSelected = selectedItems.indexOf(deviceId) !== -1;
                    const isOnline = lastSeenAt && new Date(lastSeenAt).valueOf() > (Date.now() - ONLINE_TIME_DURATION);

                    return (
                      <TableRow hover key={deviceId} tabIndex={-1} role="checkbox" selected={isSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} onChange={(event) => handleSelectItem(event, deviceId)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Tooltip title={`Created at ${fDateTime(createdAt)}`}>
                            <>
                              {getDeviceModelName(model)}
                              <Typography variant="subtitle2" noWrap>
                                {serialNumber}
                              </Typography>
                            </>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="left">
                          <EditableName deviceId={deviceId} value={name} />
                        </TableCell>

                        <TableCell align="left">
                          <Tooltip title={`Last online: ${fDateTime(lastSeenAt)}`}>
                            <Label color={isOnline ? 'success' : 'error'}>{isOnline ? 'Online' : 'Offline'}</Label>
                          </Tooltip>
                          {isOnline ? (String(batteryPercent) === '-100' ? 'Cannot read battery' : `🔋${Math.round(Number(batteryPercent))}% ${isCharging ? '🔌' : ''}`) : ''}
                        </TableCell>

                        <TableCell align="left">
                          <FormControl sx={{ width: 170 }} size="small">
                            <InputLabel id={`device-${deviceId}-mode-label`}>Device Mode</InputLabel>
                            <Select
                              labelId={`device-${deviceId}-mode-label`}
                              id={`device-${deviceId}-mode`}
                              value={mode}
                              label="Device Mode"
                              onChange={(event) => handleUpdateDevicesMode([deviceId], event.target.value)}
                            >
                              <MenuItem value="check-imessage">Check iMessage</MenuItem>
                              <MenuItem value="send-imessage">Send iMessage</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell align="left">
                          {getDeviceStatusLabel(mode, status, subStatus)}
                          {status === 'stop' ? (
                            <IconButton size="small" sx={{ color: 'primary.main' }} onClick={() => handleUpdateDevicesStatus([deviceId], 'running')}>
                              <Iconify icon={'eva:play-circle-outline'} />
                            </IconButton>
                          ) : (
                            <IconButton size="small" sx={{ color: 'error.main' }} onClick={() => handleUpdateDevicesStatus([deviceId], 'stop')}>
                              <Iconify icon={'eva:stop-circle-outline'} />
                            </IconButton>
                          )}
                        </TableCell>

                        <TableCell align="left">{getDeviceStopReasonLabel(stopReason)}</TableCell>

                        <TableCell align="left">{lastPerformTaskAt ? <ReactTimeAgo date={new Date(lastPerformTaskAt)} locale="en-US" /> : null}</TableCell>

                        <TableCell align="left">{getDeviceStatisticLabel(sendMessageCount, sendMessageSuccessCount, sendMessageFailedCount)}</TableCell>
                        {/* <TableCell align="left">{getDeviceStatisticLabel(sendMessageCount, lastPerformTaskAt, 'primary')}</TableCell>
                        <TableCell align="left">{getDeviceStatisticLabel(sendMessageSuccessCount, lastSendMessageSuccessAt, 'success')}</TableCell>
                        <TableCell align="left">{getDeviceStatisticLabel(, lastSendMessageFailedAt, 'error')}</TableCell> */}

                        <TableCell align="right">
                          {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton> */}
                          <IconButton size="small" sx={{ color: 'error.main' }} onClick={() => setOpenDeleteDialog(deviceId)}>
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {isLoadingDevices && (
                    <TableRow style={{ height: 53 * perPage }}>
                      <TableCell colSpan={10}>
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
            component="div"
            count={dataCount}
            rowsPerPage={perPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }} onClick={() => setOpenDeleteDialog(deviceId)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover > */}

      <Dialog
        open={!!openDeleteDialog}
        onClose={() => { setOpenDeleteDialog(null); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure want to delete this device?
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This operation is not revertable!
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button color="info" onClick={() => { setOpenDeleteDialog(null); }} autoFocus>Cancel</Button>
          <Button color="error" disabled={isDeleting} onClick={handleDeleteDevice}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
