import { omitBy } from "lodash";
import { apiFetch } from "../useApi";

export default async function getMessages({ queryKey }) {
  const [, , filter] = queryKey;
  const { searchValue, filterStatus, orderBy, orderDirection, perPage, page } = filter;

  let status = null
  if (filterStatus) {
    if (filterStatus === 'success') {
      status = ['sent', 'delivered', 'read'];
    } else if (filterStatus !== 'total') {
      status = filterStatus;
    }
  }

  const queryParams = new URLSearchParams(omitBy({
    search: searchValue,
    order_by: orderBy,
    order_direction: orderDirection,
    per_page: perPage,
    page,
  }, value => value == null || value === '' || value === []));
  if (Array.isArray(status) && status.length) {
    status.forEach((_status) => queryParams.append('status', _status));
  } else if (status) {
    queryParams.append('status', status);
  }

  const response = await apiFetch(`/messages?${queryParams.toString()}`);
  return response.json();
}
