import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactTimeAgo from 'react-time-ago';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Switch } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import getProfile from '../../../hooks/queries/getProfile';
import userSettings from '../../../hooks/mutations/userSettings';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import { SnackbarContext } from '../../../components/snackbar/SnackbarContext';
//
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  const [globalStatus, setGlobalStatus] = useState(false);

  const { createNotification } = useContext(SnackbarContext);

  const queryClient = useQueryClient();
  const { data: userProfile } = useQuery(['profile'], getProfile, {});

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!userProfile) return;
    setGlobalStatus(!!userProfile?.globalStatus);
  }, [userProfile, userProfile?.globalStatus]);

  const updateSettingsMutation = useMutation(userSettings, {
    onMutate: () => {
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['profile'])
      createNotification('success', 'Update global status success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Update global status failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
    },
  });

  const handleToggleGlobalStatus = () => {
    updateSettingsMutation.mutate({
      settings: {
        globalStatus: !globalStatus,
      }
    });
  };

  const accountInfo = useMemo(() => {
    if (userProfile && userProfile?.expiredAt) {
      const timestampLeft = new Date(userProfile.expiredAt).valueOf() - Date.now();
      const statusColor = timestampLeft > 86400000 ? 'success.dark' : (timestampLeft > 0 ? 'warning.dark' : 'error.main');
      return (
        <>
          <Typography variant="subtitle2" color={statusColor}>Max Devices: {userProfile.deviceCount ?? 0}</Typography>
          <Typography variant="subtitle2" color={statusColor}>{timestampLeft > 0 ? 'Expire' : 'Expired'}: {<ReactTimeAgo date={new Date(userProfile.expiredAt)} locale="en-US" />}</Typography>
        </>
      );
    }
    return (
      <>
        <Typography variant="subtitle2" color="error.main">Max Devices: {userProfile?.deviceCount ?? 0}</Typography>
        <Typography variant="subtitle2" color="error.main">Account Expired</Typography>
      </>
    );
  }, [userProfile]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src="/assets/images/avatars/avatar_default.jpg" alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userProfile?.username}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <Box sx={{ mb: 2, mx: 3 }}>
        <Typography variant="subtitle2">Status: <Switch checked={globalStatus} onChange={handleToggleGlobalStatus} /> {globalStatus ? 'Running 🟢' : 'Paused 🟠'}</Typography>
        {accountInfo}
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <img src="/assets/qr-code.png" alt="QR Code" style={{ width: '200px', height: '200px' }} />
          <Typography variant="subtitle2" textAlign="center">
            Scan QR to install from Cydia
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          {/* <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box> */}

          <Button href="https://t.me/mrv4mp1r3" target="_blank" variant="contained">
            Contact Support
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
