import { useMutation, useQuery, useQueryClient } from 'react-query';
// @mui
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Grid, ToggleButtonGroup, ToggleButton, FormGroup, FormControlLabel, Switch, LinearProgress, FormControl, RadioGroup, Radio, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SnackbarContext } from '../../../components/snackbar/SnackbarContext';
import Iconify from '../../../components/iconify/Iconify';
import getProfile from '../../../hooks/queries/getProfile';
import userSettings from '../../../hooks/mutations/userSettings';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

// ----------------------------------------------------------------------

Settings.propTypes = {
};

const AvailableHours = [...Array(24).keys()];

export default function Settings() {
  const { getItem, setItem } = useLocalStorage();
  const [expanded, setExpanded] = useState(() => getItem('user_settings_expanded') !== 'false');
  const [sendIMessageDelayType, setSendIMessageDelayType] = useState('fixed');
  const [sendImessageAllowHours, setSendImessageAllowHours] = useState([]);
  const [sendIMessageDelayMin, setSendIMessageDelayMin] = useState(10);
  const [sendIMessageDelayMax, setSendIMessageDelayMax] = useState(10);
  const [sendIMessageMaxRetry, setSendIMessageMaxRetry] = useState(3);
  const [rateLimitPeriodDuration, setRateLimitPeriodDuration] = useState(60);
  const [rateLimitPeriodCount, setRateLimitPeriodCount] = useState(20);
  const [rateLimitDelayDuration, setRateLimitDelayDuration] = useState(300);
  const [sendIMessageAutoDelete, setSendIMessageAutoDelete] = useState(false);

  const queryClient = useQueryClient();
  const { createNotification } = useContext(SnackbarContext);

  const { isLoading: isLoadingProfile, data: userProfile } = useQuery(['profile'], getProfile, {});

  useEffect(() => {
    if (!userProfile) return;
    if (userProfile.sendImessageAllowHours?.length) {
      setSendImessageAllowHours(userProfile.sendImessageAllowHours);
    } else {
      setSendImessageAllowHours(AvailableHours);
    }
    setSendIMessageDelayMin(userProfile.sendIMessageDelayMin);
    if (userProfile.sendIMessageDelayMax > userProfile.sendIMessageDelayMin) {
      setSendIMessageDelayType('random');
      setSendIMessageDelayMax(userProfile.sendIMessageDelayMax);
    } else {
      setSendIMessageDelayType('fixed');
      setSendIMessageDelayMax(userProfile.sendIMessageDelayMin);
    }
    setSendIMessageMaxRetry(userProfile.sendIMessageMaxRetry);
    setRateLimitPeriodDuration(userProfile.rateLimitPeriodDuration);
    setRateLimitPeriodCount(userProfile.rateLimitPeriodCount);
    setRateLimitDelayDuration(userProfile.rateLimitDelayDuration);
    setSendIMessageAutoDelete(!!userProfile?.sendIMessageAutoDelete);
  }, [userProfile, userProfile?.rateLimitDelayDuration, userProfile?.rateLimitPeriodCount, userProfile?.rateLimitPeriodDuration, userProfile?.sendIMessageDelayMax, userProfile?.sendIMessageDelayMin, userProfile?.sendIMessageMaxRetry, userProfile?.sendImessageAllowHours, userProfile?.sendIMessageAutoDelete]);

  const updateSettingsMutation = useMutation(userSettings, {
    onMutate: () => {
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['profile'])
      createNotification('success', 'Update settings success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Update settings failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
    },
  });

  const handleChange = (event) => {
    setExpanded(!expanded);
    setItem('user_settings_expanded', !expanded);
  };

  const handleSelectHours = (event, newHours) => {
    setSendImessageAllowHours(newHours);
  };

  const handleSaveSettings = () => {
    if (sendIMessageDelayMin < 3 || sendIMessageDelayMax < 3) {
      createNotification('error', 'Delay must be >= 3 second', 5000);
      return;
    }
    if (sendIMessageDelayMax < sendIMessageDelayMin) {
      createNotification('error', 'Delay max must > delay min', 5000);
      return;
    }
    if (sendIMessageMaxRetry < 0) {
      createNotification('error', 'Retry count should >= 0', 5000);
      return;
    }
    if (rateLimitDelayDuration < 60) {
      return;
    }
    if (rateLimitPeriodCount < 1) {
      return;
    }
    if (rateLimitPeriodDuration < 10) {
      createNotification('error', 'Rate limit protection duration should >= 10', 5000);
      return;
    }

    updateSettingsMutation.mutate({
      settings: {
        sendImessageAllowHours,
        sendIMessageDelayMin,
        sendIMessageDelayMax: (sendIMessageDelayType === 'random') ? sendIMessageDelayMax : sendIMessageDelayMin,
        sendIMessageMaxRetry,
        rateLimitPeriodDuration,
        rateLimitPeriodCount,
        rateLimitDelayDuration,
        sendIMessageAutoDelete,
      }
    });
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary>
        <Box display="flex" sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
          <Typography variant="h6" >Settings</Typography>
          {expanded ? <Iconify icon="eva:arrow-up-fill" /> : <Iconify icon="eva:arrow-down-fill" />}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ flexGrow: 1 }}>
          {isLoadingProfile ? <LinearProgress />
            : <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 2,
                }}>
                  <Tooltip title="Set hours of day allow to send messages">
                    <Typography variant="subtitle1">Allow Send Hours:</Typography>
                  </Tooltip>
                  <ToggleButtonGroup
                    color="primary"
                    size="small"
                    value={sendImessageAllowHours}
                    onChange={handleSelectHours}
                    aria-label="text formatting"
                  >
                    {AvailableHours.map((key) => (
                      <ToggleButton key={key} value={key}>
                        {key}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} display="flex" sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 2,
                }}>
                  <Typography variant="subtitle1">Delay between messages:</Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="delay-type"
                      name="row-radio-buttons-group"
                      value={sendIMessageDelayType ?? ''}
                      onChange={(event) => setSendIMessageDelayType(event?.target?.value)}
                    >
                      <FormControlLabel value="fixed" control={<Radio />} label="Fixed" />
                      <FormControlLabel value="random" control={<Radio />} label="Random" />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <Tooltip title={sendIMessageDelayType === 'fixed' ? 'Number of seconds random we should wait after sending message' : 'Minimum seconds random we should wait after sending message'}>
                      <TextField
                        value={sendIMessageDelayMin}
                        onChange={(event) => { if (event?.target?.value > 0) { setSendIMessageDelayMin(event.target.value) } }}
                        label={sendIMessageDelayType === 'fixed' ? 'Delay seconds' : 'Delay seconds min'}
                        size="small"
                      />
                    </Tooltip>
                  </FormControl>
                  {sendIMessageDelayType === 'random'
                    &&
                    <FormControl>
                      <Tooltip title="Maximum seconds random we should wait after sending message">
                        <TextField
                          error={sendIMessageDelayMax < sendIMessageDelayMin}
                          helperText={sendIMessageDelayMax < sendIMessageDelayMin ? 'Must >= delay min' : ''}
                          value={sendIMessageDelayMax ?? ''}
                          onChange={(event) => { if (event?.target?.value > 0) { setSendIMessageDelayMax(event.target.value) } }}
                          label="Delay seconds max"
                          size="small"
                        />
                      </Tooltip>
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={12} display="flex" sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 2,
                }}>
                  <Typography variant="subtitle1">Maximum send retry:</Typography>
                  <FormControl>
                    <Tooltip title="How many times we should retry sending the message if send failed?">
                      <TextField
                        error={sendIMessageMaxRetry < 0}
                        helperText={sendIMessageMaxRetry < 0 ? 'Must >= 0' : ''}
                        value={sendIMessageMaxRetry}
                        onChange={(event) => { if (event?.target?.value > 0) { setSendIMessageMaxRetry(event.target.value) } }}
                        label="Retry count when send failed"
                        size="small"
                      />
                    </Tooltip>
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 2,
                }}>
                  <Typography variant="subtitle1">Delete messages after send:</Typography>
                  <FormControlLabel control={<Switch checked={sendIMessageAutoDelete} onChange={(event) => setSendIMessageAutoDelete(event.target.checked)} />} />
                  {sendIMessageAutoDelete ? <>
                    <Typography variant="caption">AutoMess will try to delete all messages after sending 10 messages</Typography>
                    <Typography variant="caption" sx={{ color: 'error.main' }}>Lưu ý: Bật chức năng này hiện tại sẽ mất khả năng nhận diện tin nhắn Đã Đọc (Read)</Typography>
                  </> : null}
                </Grid>
                <Grid item xs={12} display="flex" sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 2,
                }}>
                  <Typography variant="subtitle1">Rate limit protection:</Typography>
                  <Typography>Wait</Typography>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    sx={{ width: '60px' }}
                    inputProps={{ style: { textAlign: 'center' } }}
                    error={rateLimitDelayDuration < 60}
                    helperText={rateLimitDelayDuration < 60 ? 'Must >= 60' : ''}
                    value={rateLimitDelayDuration}
                    onChange={(event) => { setRateLimitDelayDuration(event.target.value) }}
                  />
                  {' seconds if device send '}<TextField
                    size="small"
                    type="number"
                    variant="standard"
                    sx={{ width: '60px' }}
                    inputProps={{ style: { textAlign: 'center' } }}
                    error={rateLimitPeriodCount < 1}
                    helperText={rateLimitPeriodCount < 1 ? 'Must > 0' : ''}
                    value={rateLimitPeriodCount}
                    onChange={(event) => { setRateLimitPeriodCount(event.target.value) }}
                  />
                  {' failed messages in '}<TextField
                    size="small"
                    type="number"
                    variant="standard"
                    sx={{ width: '60px' }}
                    inputProps={{
                      style: { textAlign: 'center' },
                      inputMode: 'numeric', pattern: '[0-9]*'
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={rateLimitPeriodDuration < 10}
                    helperText={rateLimitPeriodDuration < 10 ? 'Must >= 10' : ''}
                    value={rateLimitPeriodDuration}
                    onChange={(event) => { setRateLimitPeriodDuration(event.target.value) }}
                  />{' seconds'}
                </Grid>
                {/* <Grid item xs={12} display="flex" sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 2,
                }}>
                  <Typography variant="subtitle1">Delete messages after send:</Typography>
                  <FormControlLabel control={<Switch checked={sendIMessageAutoDelete} onChange={(event) => setSendIMessageAutoDelete(event.target.checked)} />} />
                  {sendIMessageAutoDelete ? <>
                    <Typography variant="caption">AutoMess will try to delete all messages after sending 10 messages</Typography>
                    <Typography variant="caption" sx={{ color: 'error.main' }}>Lưu ý: Bật chức năng này hiện tại sẽ mất khả năng nhận diện tin nhắn Đã Đọc (Read)</Typography>
                  </> : null}
                </Grid> */}
                <Grid item xs={12} display="flex" sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: 2,
                }}>
                  <Button variant="contained" color="primary" startIcon={<Iconify icon="eva:save-outline" />} onClick={handleSaveSettings}>Save</Button>
                  {/* <Button variant="contained" color="warning" startIcon={<Iconify icon="eva:undo-outline" />}>Reset</Button> */}
                </Grid>
              </Grid>
            </FormGroup>
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
