// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const icon = (icon) => <Iconify icon={icon} />;

const navConfig = [
  {
    title: 'Devices',
    path: '/dashboard/device',
    icon: icon('eva:smartphone-outline'),
  },
  // {
  //   title: 'Campaigns',
  //   path: '/dashboard/campaigns',
  //   icon: icon('eva:folder-outline'),
  // },
  {
    title: 'Send iMessages',
    path: '/dashboard/send-imessage',
    icon: icon('eva:message-circle-outline'),
  },
  {
    title: 'Check iMessages',
    path: '/dashboard/check-imessage',
    icon: icon('eva:person-done-outline'),
  },
];

export default navConfig;
