import CONSTANTS from "../../constants";

export default async function userLogin({ username, password }) {
  const response = await fetch(`${CONSTANTS.API_URL}/auth/login`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
  return response.json();
}
