import { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, FormGroup, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { AuthContext } from '../../../contexts/AuthContext';
import Iconify from '../../../components/iconify';
import { SnackbarContext } from '../../../components/snackbar/SnackbarContext';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { accessToken, tokenData, login } = useContext(AuthContext);

  const { createNotification } = useContext(SnackbarContext);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      if (!username || !password) {
        createNotification('error', 'Must enter username and password', 5000);
        return;
      }
      const loginResult = await login(username, password);
      if (loginResult.error) {
        createNotification('error', loginResult.message ?? loginResult.error ?? 'Login failed', 5000);
      }
    } catch (error) {
      console.log(error);
      createNotification('error', 'Login failed', 5000);
    } finally {
      setIsLoading(false);
    }
  };

  if (accessToken && tokenData?.username) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <form onSubmit={handleLogin}>
        <FormGroup>
          <Stack spacing={3}>
            <TextField name="username" label="Username" value={username} onChange={(event) => { setUsername(event.target.value ?? '') }} />

            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(event) => { setPassword(event.target.value ?? '') }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link variant="subtitle2" underline="hover" href="https://t.me/mrv4mp1r3" target="_contact">
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin} disabled={isLoading} >
            {isLoading ? <CircularProgress size="20px" /> : null} Login
          </LoadingButton>
        </FormGroup>
      </form>
    </>
  );
}
