import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';
// component

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

CampaignListToolbar.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string),
};

export default function CampaignListToolbar({ selectedItems }) {
  return (
    <StyledRoot
      sx={{
        ...(selectedItems?.length > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <></>
    </StyledRoot>
  );
}
