import { forwardRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { Alert as MuiAlert, Snackbar } from '@mui/material';
import TimeAgo from 'javascript-time-ago';
import TimeAgoEN from 'javascript-time-ago/locale/en.json';
// import TimeAgoVI from 'javascript-time-ago/locale/vi.json';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { AuthProvider } from './contexts/AuthContext';
import { SnackbarContext } from './components/snackbar/SnackbarContext';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
// ----------------------------------------------------------------------

TimeAgo.addDefaultLocale(TimeAgoEN);

const queryClient = new QueryClient()

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function App() {
  const [snack, setSnack] = useState({
    message: '',
    open: false,
    severity: 'info',
  });
  const [closeTimer, setCloseTimer] = useState();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    if (closeTimer) clearTimeout(closeTimer);
    setSnack({
      message: '',
      open: false,
    });
  };

  const createNotification = (severity, message, duration = 6000) => {
    if (closeTimer) clearTimeout(closeTimer);
    setCloseTimer(setTimeout(handleClose, duration));
    setSnack({
      open: true,
      severity,
      message,
    });
  };

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <SnackbarContext.Provider value={{ createNotification, snack, setSnack }}>
            <Snackbar
              open={snack.open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={handleClose} severity={snack.severity} sx={{ width: '100%' }}>
                {snack.message}
              </Alert>
            </Snackbar>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <ScrollToTop />
                <StyledChart />
                <Router />
              </AuthProvider>
            </QueryClientProvider>
          </SnackbarContext.Provider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider >
  );
}
