import { saveAs } from 'file-saver';
import CONSTANTS from "../../constants";
import { fDateTime } from '../../utils/formatTime';

export default async function exportAddresses(exportType) {
  const token = localStorage.getItem('token');
  const response = await fetch(`${CONSTANTS.API_URL}/addresses/export`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ exportType }),
  });

  if (response.ok) {
    saveAs(await response.blob(), `check_${fDateTime(new Date())}_${exportType || 'all'}.xlsx`);
  }
}
