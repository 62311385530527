import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DevicePage from './pages/DevicePage';
import CampaignPage from './pages/CampaignPage';
import SendIMessagePage from './pages/SendIMessagePage';
import CheckIMessagePage from './pages/CheckIMessagePage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/device" />, index: true },
        { path: 'device', element: <DevicePage /> },
        { path: 'campaigns', element: <CampaignPage /> },
        { path: 'send-imessage', element: <SendIMessagePage /> },
        { path: 'check-imessage', element: <CheckIMessagePage /> },
        { path: '*', element: <Page404 /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/device" />, index: true },
        { path: '*', element: <Page404 /> },
      ],
    },
    { path: '*', element: <Page404 /> },
  ]);

  return routes;
}
