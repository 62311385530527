import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, Input, InputAdornment, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(Input)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

DeviceListToolbar.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onUpdateDevicesMode: PropTypes.func,
  onUpdateDevicesStatus: PropTypes.func,
};

export default function DeviceListToolbar({ selectedItems, filterName, onFilterName, onUpdateDevicesMode, onUpdateDevicesStatus }) {
  return (
    <StyledRoot
      sx={{
        ...(selectedItems?.length > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {selectedItems?.length > 0 ? (
        <Typography component="div" variant="subtitle1">
          {selectedItems?.length} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search device..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {selectedItems?.length > 0 ? (
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <FormControl sx={{ width: 170 }} size="small">
            <InputLabel id={`device-mode-label`}>Device Mode</InputLabel>
            <Select
              labelId={`device-mode-label`}
              id={`device-mode`}
              value={''}
              label="Device Mode"
              onChange={(event) => onUpdateDevicesMode(selectedItems, event.target.value, true)}
            >
              <MenuItem value="check-imessage">Check iMessage</MenuItem>
              <MenuItem value="send-imessage">Send iMessage</MenuItem>
            </Select>
          </FormControl>
          <Tooltip title="Start devices">
            <IconButton size="large" sx={{ color: 'primary.main' }} onClick={() => onUpdateDevicesStatus(selectedItems, 'running', true)}>
              <Iconify icon={'eva:play-circle-outline'} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Stop devices">
            <IconButton size="medium" sx={{ color: 'error.main' }} onClick={() => onUpdateDevicesStatus(selectedItems, 'stop', true)}>
              <Iconify icon={'eva:stop-circle-outline'} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Delete">
            <IconButton>
              <Iconify icon="eva:trash-2-fill" />
            </IconButton>
          </Tooltip> */}
        </Stack>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}
