import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import ReactTimeAgo from 'react-time-ago';

// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Chip,
  Link,
} from '@mui/material';
import {
  useMutation,
  useQuery,
  useQueryClient,
  // useMutation,
} from 'react-query'
import { Link as RouterLink } from 'react-router-dom';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { SnackbarContext } from '../components/snackbar/SnackbarContext';
// sections
import { CampaignListHead, CampaignListToolbar } from '../sections/@dashboard/campaign';
// hooks
import { useLocalStorage } from '../hooks/useLocalStorage';
import getCampaigns from '../hooks/queries/getCampaigns';
import createCampaign from '../hooks/mutations/createCampaign';
import patchCampaign from '../hooks/mutations/patchCampaign';
import deleteCampaign from '../hooks/mutations/deleteCampaign';
import updateCampaignsStatus from '../hooks/mutations/updateCampaignsStatus';
// utils
// import { fDateTime } from '../utils/formatTime';
import { fNumber } from '../utils/formatNumber';

// ----------------------------------------------------------------------


const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Type' },
  { id: 'status', label: 'Status' },
  // { id: 'devices', label: 'Devices' },
  { id: 'statistic.total', label: 'Send', width: '60px' },
  { id: 'statistic.pending', label: 'Pending', width: '60px' },
  { id: 'statistic.success', label: 'Success', width: '60px' },
  { id: 'statistic.failed', label: 'Failed', width: '60px' },
  { id: 'createdAt', label: 'Created At', width: '140px' },
  { id: '' },
];

// ----------------------------------------------------------------------

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

function stringComparator(orderBy, order) {
  return (a, b) => order === 'desc' ? -collator.compare(a[orderBy], b[orderBy]) : collator.compare(a[orderBy], b[orderBy]);
}

function dateComparator(orderBy, order) {
  return (a, b) => {
    const direction = order === 'desc' ? -1 : 1;
    const timeA = new Date(a[orderBy] ?? 0).valueOf();
    const timeB = new Date(b[orderBy] ?? 0).valueOf();
    if (timeA === timeB) return 0;
    if (timeA < timeB) return direction * 1;
    return direction * -1;
  }
}

function getComparator(order, orderBy) {
  if (orderBy === 'createdAt') {
    return dateComparator(orderBy, order);
  }
  return stringComparator(orderBy, order);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_campaign) => _campaign.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


EditableName.propTypes = {
  campaignId: PropTypes.string,
  value: PropTypes.string,
};

function EditableName({ campaignId, value: inputValue }) {
  const [value, setValue] = useState(inputValue ?? '');
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const queryClient = useQueryClient();
  const { createNotification } = useContext(SnackbarContext);

  const patchCampaignMutation = useMutation(patchCampaign, {
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns']);
      createNotification('success', 'Rename campaign success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Rename campaign failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
      setIsEditing(false);
      setIsSaving(false);
    },
  });

  const handleSave = () => {
    patchCampaignMutation.mutate({ campaignId, campaignInfo: { name: value } });
  };

  const handleCancel = () => {
    setValue(inputValue);
    setIsEditing(false);
  };

  const handleMouseOver = () => { setIsHovering(true); }
  const handleMouseOut = () => { setIsHovering(false); }

  const handleKeyDown = (event) => {
    if (event.code === 'Enter') {
      handleSave();
    } else if (event.code === 'Escape') {
      handleCancel();
    }
  };

  return !isEditing ? (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}>
      <Typography
        sx={{
          height: '24px',
          minWidth: '100%',
          width: '100%',
        }}
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {value}
      </Typography>
      {isHovering
        && <IconButton size="small" color="inherit" onClick={() => setIsEditing(true)}>
          <Iconify icon={'eva:edit-fill'} />
        </IconButton>}
    </Box>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        variant="standard"
        autoFocus
        sx={{ maxWidth: '100px' }}
        inputProps={{
          sx: {
            maxWidth: '100px',
            width: '100%',
            padding: 1,
          }
        }}
        value={value}
        onChange={(event) => setValue(event.target.value ?? '')}
        onKeyDown={handleKeyDown}
        disabled={isSaving}
      />
      <IconButton size="small" color="inherit" onClick={handleSave} disabled={isSaving}>
        <Iconify icon={'eva:checkmark-fill'} />
      </IconButton>
      <IconButton size="small" color="inherit" onClick={handleCancel} disabled={isSaving}>
        <Iconify icon={'eva:close-fill'} />
      </IconButton>
    </Box>
  );
}

const getCampaignStatusLabel = (status) => {
  switch (status) {
    case 'stopped':
      return <Label color="error">Stopped</Label>;
    case 'running':
      return <Label color="success">Running</Label>;
    default:
      return <Label>{status}</Label>
  }
};

const getCampaignStatisticLabel = (count, color) => (
  <Chip label={fNumber(count ?? 0)} color={color} size="small" />
);

export default function CampaignPage() {
  const { getItem, setItem } = useLocalStorage();
  // const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [orderBy, _setOrderBy] = useState(() => getItem('campaign_orderBy') ?? '_id');
  const setOrderBy = (value) => {
    _setOrderBy(value);
    setItem('campaign_orderBy', value);
  };
  const [order, _setOrder] = useState(() => getItem('campaign_order') ?? 'asc');
  const setOrder = (value) => {
    _setOrder(value);
    setItem('campaign_order', value);
  };
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [perPage, _setRowsPerPage] = useState(() => Number(getItem('campaign_perPage') ?? 100));
  const setRowsPerPage = (value) => {
    _setRowsPerPage(value);
    setItem('campaign_perPage', value);
  };
  const [campaignName, setCampaignName] = useState('');
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const { isLoading: isLoadingCampaigns, data: campaigns } = useQuery(['campaigns', 'send-imessage'], getCampaigns, {
    refetchInterval: 60000,
  });
  const dataCount = (campaigns ?? []).length;

  const queryClient = useQueryClient();
  const { createNotification } = useContext(SnackbarContext);

  const createCampaignMutation = useMutation(createCampaign, {
    onMutate: () => {
      setIsCreating(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns']);
      createNotification('success', 'Create campaign success', 3000);
      setOpenCreateDialog(false);
    },
    onError: (error) => {
      createNotification('error', `Create campaign failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
      setIsCreating(false);
    },
  });

  const updateCampaignsStatusMutation = useMutation(updateCampaignsStatus, {
    onMutate: () => {
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns']);
      createNotification('success', 'Updated campaign status success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Update campaign status failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
    },
  });

  const deleteCampaignMutation = useMutation(deleteCampaign, {
    onMutate: () => {
      setIsDeleting(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns']);
      createNotification('success', 'Delete campaign success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Delete campaign failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
      setOpenDeleteDialog(null);
      setIsDeleting(false);
    },
  });

  const handleRequestSort = (event, property) => {
    if (property === 'info') {
      event.preventDefault();
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAll = (event, data) => {
    if (event.target.checked) {
      const newSelecteds = (data ?? []).map((n) => n._id);
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCreateCampaign = (name, type) => {
    createCampaignMutation.mutate({ name, type })
  };

  const handleUpdateCampaignsStatus = (campaignIds, status) => {
    updateCampaignsStatusMutation.mutate({ campaignIds, status })
  };

  const handleDeleteCampaign = () => {
    deleteCampaignMutation.mutate({ campaignId: openDeleteDialog });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * perPage - dataCount) : 0;
  const filteredCampaigns = applySortFilter(campaigns ?? [], getComparator(order, orderBy), filterName);

  return (
    <>
      <Helmet>
        <title>SpamImess - Campaigns</title>
      </Helmet>

      <Container maxWidth={false} sx={{ minWidth: '1600px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Campaigns
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-circle-outline" />} onClick={() => setOpenCreateDialog(true)}>
            Create campaign
          </Button>
        </Stack>

        <Card>
          <CampaignListToolbar selectedItems={selectedItems} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CampaignListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataCount}
                  numSelected={selectedItems.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={(event) => handleSelectAll(event, campaigns)}
                />
                <TableBody>
                  {!isLoadingCampaigns && !!dataCount && filteredCampaigns.slice(page * perPage, page * perPage + perPage).map((row) => {
                    const { _id: campaignId, name, type, status, devices, statistic, createdAt } = row;
                    const isSelected = selectedItems.indexOf(campaignId) !== -1;

                    return (
                      <TableRow hover key={campaignId} tabIndex={-1} role="checkbox" selected={isSelected}>
                        <TableCell align="left">
                          {/* <EditableName campaignId={campaignId} value={name} /> */}
                          {/* <Link underline="none"> */}
                          <Link component={RouterLink} to="/dashboard/send-imessage">{name ?? 'Default'}</Link>
                        </TableCell>

                        <TableCell align="left">
                          {type}
                        </TableCell>

                        <TableCell align="left">
                          {getCampaignStatusLabel(status)}
                          {status === 'stopped' ? (
                            <IconButton size="small" sx={{ color: 'primary.main' }} onClick={() => handleUpdateCampaignsStatus([campaignId], 'running')}>
                              <Iconify icon={'eva:play-circle-outline'} />
                            </IconButton>
                          ) : (
                            <IconButton size="small" sx={{ color: 'error.main' }} onClick={() => handleUpdateCampaignsStatus([campaignId], 'stopped')}>
                              <Iconify icon={'eva:stop-circle-outline'} />
                            </IconButton>
                          )}
                        </TableCell>

                        {/* TODO: Show devices count */}
                        {/* <TableCell align="left">
                          {devices ? '' : ''}
                        </TableCell> */}

                        <TableCell align="left">{getCampaignStatisticLabel(statistic?.total ?? 0, undefined)}</TableCell>

                        <TableCell align="left">{getCampaignStatisticLabel((statistic?.pending ?? 0) + (statistic?.sending ?? 0), 'info')}</TableCell>

                        <TableCell align="left">{getCampaignStatisticLabel((statistic?.sent ?? 0) + (statistic?.delivered ?? 0) + (statistic?.read ?? 0), 'success')}</TableCell>

                        <TableCell align="left">{getCampaignStatisticLabel(statistic?.failed ?? 0, 'error')}</TableCell>

                        <TableCell align="left">{createdAt ? <ReactTimeAgo date={new Date(createdAt)} locale="en-US" /> : null}</TableCell>

                        <TableCell align="right">
                          <IconButton size="small" sx={{ color: 'error.main' }} onClick={() => setOpenDeleteDialog(campaignId)}>
                            <Iconify icon={'eva:trash-2-outline'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {isLoadingCampaigns && (
                    <TableRow style={{ height: 53 * perPage }}>
                      <TableCell colSpan={10}>
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
            component="div"
            count={dataCount}
            rowsPerPage={perPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
        open={!!openDeleteDialog}
        onClose={() => { setOpenDeleteDialog(null); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure want to delete this campaign?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This operation is not revertable!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={() => { setOpenDeleteDialog(null); }} autoFocus>Cancel</Button>
          <Button color="error" disabled={isDeleting} onClick={handleDeleteCampaign}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <DialogTitle>Create new campaign</DialogTitle>
        <DialogContent>
          {/* <DialogContentText></DialogContentText> */}
          <form onSubmit={() => handleCreateCampaign(campaignName, 'send-imessage')}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Campaign Name"
              type="text"
              fullWidth
              variant="standard"
              value={campaignName} onChange={(event) => { setCampaignName(event.target.value ?? '') }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={() => setOpenCreateDialog(false)}>Cancel</Button>
          <Button color="primary" onClick={() => handleCreateCampaign(campaignName, 'send-imessage')} disabled={isCreating}>Create</Button>
        </DialogActions>
      </Dialog >
    </>
  );
}
