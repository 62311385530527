import React, { createContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import userLogin from '../hooks/mutations/userLogin';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { getItem, setItem, removeItem } = useLocalStorage();
  const [accessToken, setAccessToken] = useState(() => getItem("token"));
  const [tokenData, setTokenData] = useState(() => {
    try {
      const tokenData = getItem("user") ?? '{}';
      return JSON.parse(tokenData);
    } catch (error) {
      console.error('getTokenData failed', error);
      return {};
    }
  });

  const userLoginMutation = useMutation(userLogin);

  const login = async (username, password) => {
    const data = await userLoginMutation.mutateAsync({ username, password });
    if (data && data?.accessToken) {
      const tokenData = {
        username,
      };
      setItem('token', data?.accessToken);
      setAccessToken(data?.accessToken);
      setItem('user', JSON.stringify(tokenData));
      setTokenData(tokenData);
      navigate('/dashboard', { replace: true });
    }
    return data;
  };

  const logout = () => {
    removeItem('token');
    setAccessToken(null);
    removeItem('user');
    setTokenData({});
  };

  return (
    <AuthContext.Provider value={{ accessToken, tokenData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
