import { apiFetch } from "../useApi";

export default async function importIMessages(file) {
  const token = localStorage.getItem('token');
  const formData = new FormData();
  formData.append('file', file);

  const response = await apiFetch(`/campaigns/imessages/import`, {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return response.json();
}
