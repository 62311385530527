import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useContext, useState } from 'react';
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  // IconButton,
  Tooltip,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { useDebounce } from 'use-debounce';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { SnackbarContext } from '../components/snackbar/SnackbarContext';
// sections
import { AddressListHead, AddressListToolbar } from '../sections/@dashboard/check-imessage';
// hooks
import getAddresses from '../hooks/queries/getAddresses';
import importAddresses from '../hooks/mutations/importAddresses';
import exportAddresses from '../hooks/mutations/exportAddresses';
import deleteAddresses from '../hooks/mutations/deleteAddresses';
import retryAddresses from '../hooks/mutations/retryAddresses';
import getDevices from '../hooks/queries/getDevices';
// utils
import { fDateTime } from '../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'checkCount', label: 'Check Count', alignRight: false },
  { id: 'checkedDevice', label: 'Device', alignRight: false },
  { id: 'time', label: 'Last Updated', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const getAddressStatusLabel = (status) => {
  switch (status) {
    case 'pending':
      return <Label color="info">{sentenceCase(status)}</Label>;
    case 'checking':
      return <Label color="primary">{sentenceCase(status)}</Label>;
    case 'available':
      return <Label color="success">{sentenceCase(status)}</Label>;
    case 'unavailable':
      return <Label color="error">{sentenceCase(status)}</Label>;
    default:
      return <Label>{sentenceCase(status)}</Label>
  }
};

export default function CheckIMessagePage() {
  const [open, setOpen] = useState(null);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filterStatus, setFilterStatus] = useState('total');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [isExporting, setIsExporting] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const isMutating = isImporting || !!isExporting || isDeleting || isRetrying;

  const [searchValueDebounced] = useDebounce(searchValue, 500);

  const queryClient = useQueryClient();
  const { createNotification } = useContext(SnackbarContext);

  const { isLoading: isLoadingAddresses, data: addressesResponse } = useQuery(['addresses', 'list', {
    searchValue: searchValueDebounced,
    filterStatus,
    orderBy,
    orderDirection,
    perPage,
    page: page + 1,
  }], getAddresses, {
    refetchInterval: 60000,
  });
  const { data: devices } = useQuery(['devices'], getDevices, {});

  const addresses = addressesResponse?.addresses ?? [];
  const dataCount = addresses.length;
  const totalCount = addressesResponse?.pagination?.totalCount ?? 1;

  const importAddressesMutation = useMutation(importAddresses, {
    onMutate: () => {
      setIsImporting(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['addresses']);
      createNotification('success', 'Import file success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Import file failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
      setIsImporting(false);
    },
  });

  const exportAddressesMutation = useMutation(exportAddresses, {
    onMutate: (exportType) => {
      setIsExporting(exportType ?? 'all');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['addresses']);
      createNotification('success', 'Export result success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Export result failed ${error?.message ?? ''}`, 5000);
    },
    onSettled: () => {
      setIsExporting('');
    },
  });

  const deleteAddressesMutation = useMutation(deleteAddresses, {
    onMutate: () => {
      setIsDeleting(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['addresses']);
      createNotification('success', 'Delete addresses success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Delete addresses failed ${error.message}`, 5000);
    },
    onSettled: () => {
      setIsDeleting(false);
    },
  });

  const retryAddressesMutation = useMutation(retryAddresses, {
    onMutate: () => {
      setIsRetrying(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['addresses']);
      createNotification('success', 'Retry addresses success', 3000);
    },
    onError: (error) => {
      createNotification('error', `Retry addresses failed ${error.message}`, 5000);
    },
    onSettled: () => {
      setIsRetrying(false);
    },
  });

  // const handleOpenMenu = (event) => {
  //   setOpen(event.currentTarget);
  // };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAll = (event, data) => {
    if (event.target.checked) {
      const newSelecteds = (data ?? []).map((item) => item._id);
      setSelectedItems(newSelecteds);
      return;
    }
    setSelectedItems([]);
  };

  const handleSelectItem = (event, addressId) => {
    const selectedIndex = selectedItems.indexOf(addressId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, addressId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
    }
    setSelectedItems(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePerPage = (event) => {
    setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const handleFilterStatus = (status) => {
    setPage(0);
    if (status === filterStatus) {
      if (status !== 'total') {
        setFilterStatus('total');
      }
    } else {
      setFilterStatus(status);
    }
  };

  const handleImportAddresses = async (event) => {
    const file = event.target.files[0];

    importAddressesMutation.mutate(file);
  };

  const handleExportAddresses = (exportType) => {
    exportAddressesMutation.mutate(exportType);
  }

  const handleDeleteAddresses = (event) => {
    setDeleteDialogOpen(false);
    deleteAddressesMutation.mutate();
  }

  const handleRetryAddresses = (event) => {
    retryAddressesMutation.mutate();
  }

  const isNotFound = !!searchValue && !dataCount;

  return (
    <>
      <Helmet>
        <title>SpamImess - Check iMessages</title>
      </Helmet>

      <Container maxWidth={false} sx={{ minWidth: '1600px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Check iMessages
          </Typography>
          <Stack direction="row" alignItems="center" gap={1}>
            <Button variant="contained" color="info" startIcon={<Iconify icon="eva:archive-outline" />} href="/uploads/ImportCheckImessageTemplate.xlsx" target="_blank">Template file</Button>
            <Button variant="contained" color="primary" startIcon={isImporting ? <CircularProgress size="20px" /> : <Iconify icon="eva:upload-outline" />} component="label" disabled={isMutating}>
              <CircularProgress size="sm" /> Import
              <input type="file" hidden onChange={handleImportAddresses} disabled={isMutating} onClick={(event) => { event.target.value = null; }} />
            </Button>
            <Button variant="contained" color="info" startIcon={isExporting === 'all' ? <CircularProgress size="20px" /> : <Iconify icon="eva:download-outline" />} disabled={isMutating} onClick={() => { handleExportAddresses(); }}>
              <CircularProgress size="sm" />Export All
            </Button>
            <Button variant="contained" color="success" startIcon={isExporting === 'success' ? <CircularProgress size="20px" /> : <Iconify icon="eva:checkmark-circle-outline" />} disabled={isMutating} onClick={() => { handleExportAddresses('success'); }}>
              Export Success
            </Button>
            <Button variant="contained" color="warning" startIcon={isExporting === 'failed' ? <CircularProgress size="20px" /> : <Iconify icon="eva:alert-circle-outline" />} disabled={isMutating} onClick={() => { handleExportAddresses('failed'); }}>
              Export Failed
            </Button>
            <Button variant="contained" color="error" startIcon={isDeleting ? <CircularProgress size="20px" /> : <Iconify icon="eva:trash-2-outline" />} disabled={isMutating} onClick={() => { setDeleteDialogOpen(true); }}>
              Delete All
            </Button>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => { setDeleteDialogOpen(false); }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure want to delete all addresses?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This operation is not revertable!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="info" onClick={() => { setDeleteDialogOpen(false); }} autoFocus>Cancel</Button>
                <Button color="error" onClick={handleDeleteAddresses}>Delete All</Button>
              </DialogActions>
            </Dialog>
          </Stack>
        </Stack>

        <Card>
          <AddressListToolbar isMutating={isMutating} isRetrying={isRetrying} numSelected={selectedItems.length} filterName={searchValue} onFilterName={handleFilterByName} filterStatus={filterStatus} onFilterStatus={handleFilterStatus} onRetry={handleRetryAddresses} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <AddressListHead
                  order={orderDirection}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataCount}
                  numSelected={selectedItems.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={(event) => handleSelectAll(event, addresses)}
                />
                <TableBody>
                  {!isLoadingAddresses && !!dataCount && addresses.map((row) => {
                    const { _id: addressId, address, type, status, reason, checkCount, checkedDevice, lastCheckedAt } = row;
                    const isSelected = selectedItems.indexOf(addressId) !== -1;
                    const statusTooltip = `${reason ? `Reason: ${reason}` : ''}`;
                    const device = (devices ?? []).find((device) => checkedDevice && checkedDevice === device._id);

                    return (
                      <TableRow hover key={addressId} tabIndex={-1} role="checkbox" selected={isSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} onChange={(event) => handleSelectItem(event, addressId)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {address}
                          </Typography>
                        </TableCell>


                        <TableCell align="left">
                          <Tooltip title={statusTooltip}>{getAddressStatusLabel(status)}</Tooltip>
                        </TableCell>

                        <TableCell align="left">{checkCount}</TableCell>

                        <TableCell align="left">{device?.name || device?.serialNumber || ''}</TableCell>

                        <TableCell align="left">{status !== 'pending' ? fDateTime(lastCheckedAt) : ''}</TableCell>

                        <TableCell align="right">
                          {/* <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {isLoadingAddresses && (
                    <TableRow style={{ height: 53 * perPage }}>
                      <TableCell colSpan={8}>
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{searchValue}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {!isLoadingAddresses && <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePerPage}
          />}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
