import CONSTANTS from "../constants";

export async function apiFetch(path, options) {
  const token = localStorage.getItem('token');
  const apiUrl = `${CONSTANTS.API_URL}${path.startsWith('/') ? path : `/${path}`}`;

  const response = await fetch(apiUrl, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    ...options,
  });

  if (response.ok) {
    return response;
  }

  const resBody = await response.text();
  try {
    const resJson = JSON.parse(resBody);
    if (resJson.message) {
      throw new Error(resJson.message);
    }
    if (resJson.error) {
      throw new Error(resJson.error);
    }
    throw new Error('Request failed');
  } catch (error) {
    throw new Error(resBody);
  }
}
